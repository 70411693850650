<template>
  <v-container>
    <v-row type="flex" justify="center" align="center">
      <v-spacer />
      <v-col cols="1" class="text-right mt-0 pt-0">Pagina:</v-col>  
      <v-col cols="1">
        <v-text-field
          class="mt-0 pt-0"
          type="number"
          style="width: 40px;"
          min="1" :max="numPages"
          v-model="page"
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="text-left mt-0 pt-0">Pagine: {{numPages}}</v-col>  
      <v-col>
        <v-btn @click="$refs.pdf.print()" class="mr-5">
          Stampa
          <v-icon right dark>
            mdi-printer
          </v-icon>   
        </v-btn>
<!--         <v-btn @click="onReload" class="mr-5">
          Ricarica
          <v-icon right dark>
            mdi-reload
          </v-icon>        
        </v-btn> -->
        <v-btn @click="onClose" class="mr-5">
          Esci
          <v-icon right dark>
            mdi-exit-to-app
          </v-icon>        
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <div
        v-if="loadedRatio > 0 && loadedRatio < 1"
        style="background-color: green; color: white; text-align: center"
        :style="{ width: loadedRatio * 100 + '%' }"
      >
        {{ Math.floor(loadedRatio * 100) }}%
      </div>
      <pdf
        v-if="show"
        ref="pdf"
        style="border: 1px solid red; height: 100%;"
        :src="src"
        :page="parseInt(page)"
        :rotate="rotate"
        @progress="loadedRatio = $event"
        @error="error"
        @num-pages="numPages = $event"
        @link-clicked="page = $event"
      ></pdf>
    </div>
   </v-container>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  components: {
    pdf: pdf
  },
  props: {
    source: String
  },
  data() {
    return {
      show: true,
      loadedRatio: 0,
      page: 1,
      numPages: 1,
      rotate: 0
    }
  },
  computed: {
    src() {
      return this.source
    }
  },
  methods: {
    error: function (err) {
      console.log(err)
    },
    onClose() {
      this.$router.back()
    },
    onReload() {
      // this.$router.push({ name: 'Pdf', params: { source: this.$router.currentRoute.params.source } })
      this.$router.go(this.$router.currentRoute, {params: { source: this.$router.currentRoute.params.source }})
    },
    decrement () {
      this.page = parseInt(this.page) - 1
    },
    increment () {
      this.page = parseInt(this.page) + 1
    }
  }
}
</script>

<style>
</style>
